<template>
  <Member
    title="Prof. Mario Bussi"
    description="Direttore dell’Unità operativa Otorinolaringoiatrica presso l’Ospedale Universitario I.R.S.S. San Raffaele di Milano"
    text="Il Prof. Mario Bussi nasce a Torino il giorno 20 dicembre 1952. Dopo aver ottenuto la laurea in Medicina e Chirurgia nel 1977 con la votazione di 110/110 e lode, si è specializzato in Otorinolaringoiatria e Patologia Cervico-Facciale nel 1980 con la votazione di 70/70 e lode, sempre presso l’Università  degli Studi di Torino.

Ha in seguito conseguito il diploma Universitario in “Chirurgie Maxillo-Faciale et Chirurgie réparatrice de la Face” presso l’Universitée Paris VI (Univ. Pierre et Marie Curie) di Parigi nel 1986.

Attualmente ricopre la carica di Professore Ordinario di Otorinolaringoiatria e Direttore dell’Unità Operativa ORL presso l’Ospedale Universitario I.R.S.S. San Raffaele di Milano. E’ il Presidente della Società Italiana di Otorinolaringoiatria e Chirurgia Cervico-Facciale (SIOeChCF).

Ha ricoperto il ruolo di presidente dell’AUORL (Associazione Otorinolaringoiatri Universitari) ed è attualmente il presidente della Società Italiana di Otorinolaringoiatria (SIO).

Ha svolto ricerche in vari settori della propria specialità , ma in particolar modo orientate allo studio dei tumori della testa e del collo, alla loro chirurgia demolitiva e plastico ricostruttiva, ai problemi immunitari ORL (nella oncologia distrettuale, nell’anello di Waldeyer, nelle vie aeree superiori) e alla psico-oncologia.

E’ autore di oltre 300 pubblicazioni scientifiche nazionali ed internazionali ed è membro di numerose Società Scientifiche: Società Italiana di Otorinolaringoiatria (SIO), Associazione Italiana di Oncologia Cervico-Cefalica (AIOCC), Société Francaise d’Oto-Rhino-Laryngologie et de chirurgie de la face et du cou (SF ), European Laryngological society (ELS), American Academy of Otolaryngology, Head & Neck Surgery (AAO/HNS), ecc .

La sua esperienza chirurgica documentata, condotta presso la Clinica ORL dell’Università di Torino (in cui è stato Ricercatore e poi Professore Associato dal 1978) e poi presso l’IRCCS S. Raffaele di Milano, consta di oltre 18.000 interventi, di cui più di 18.000 eseguiti come primo operatore.

Dal 1° Febbraio 2002 è Direttore della Unità Operativa di Otorinolaringoiatria dell’Istituto Scientifico San Raffaele di Milano, dove ricopre il ruolo di Professore Ordinario in Otorinolaringoiatria e il ruolo di Direttore della Scuola di Specializzazione in Otorinolaringoiatria presso l’Università Vita-Salute S.Raffaele di Milano."
    :formation="[
      'Per il proprio approfondimento professionale, il Prof. Mario Bussi ha svolto periodi di studio e frequenza presso numerosi centri Ospedalieri e Universitari del Nord-America e della Francia, tra cui il Memorial Sloan Kettering Cancer Center di New York, il Manhattan Eye, Ear and Throat Hospital di New York, lo Straith Memorial Hospital di Detroit, l’Institut Gustave Roussy di Parigi e molti altri.',
      '',
      '',
      '',
    ]"
    publication=""
    :pub="false"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Bussi",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
